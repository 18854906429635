import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    captionControls: {},
    frameControls: {},
    aspectRatiosMono: {},
    currentMultiLayoutData: {},
    slideChangeInProgress: false
}

export const galleryPreviewSlice = createSlice({
  name: 'galleryPreview',
  initialState,
  reducers: {
    updateCaptionControls: (state, action) => {
        state.captionControls = action.payload
    },
    updateFrameControls: (state, action) => {
        state.frameControls = action.payload
    },
    updateCurrentMultiLayoutData: (state, action) => {
        state.currentMultiLayoutData = action.payload
    },
    updateAspectRationControls: (state, action) => {
        state.aspectRatiosMono = action.payload
    },
    updateSlideChangeInProgress: (state, action) => {
        state.slideChangeInProgress = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateCaptionControls, updateFrameControls, updateAspectRationControls, updateCurrentMultiLayoutData, 
    updateSlideChangeInProgress } = galleryPreviewSlice.actions
export const getCaptionControl = ({galleryPreview}) => galleryPreview?.captionControls;
export const getFrameControl = ({galleryPreview}) => galleryPreview?.frameControls;
export const getAspectRatioControl = ({galleryPreview}) => galleryPreview?.aspectRatiosMono;
export const getCurrentMultiLayoutData = ({galleryPreview}) => galleryPreview?.currentMultiLayoutData;
export const getSlideChangeInProgress = ({galleryPreview}) => galleryPreview?.slideChangeInProgress;
export default galleryPreviewSlice.reducer